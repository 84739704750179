import { PathOr } from './FunctionsBox'
import { WindowExist } from './HandleMode'
import { ParseAccountToken } from './HandleToken'

export const GA4_MEASUREMENT_ID = 'G-G6RW97DEB4'
export const GA_LOCAL_STORAGE_EVENT_NAME = 'GA4Events'
export const GA4_REGISTER_EVENT_NAME = 'swap_register'
export const GA4_SWAP_INCOME_TAX_CALC_EVENT_NAME = 'swap_incomeTax_calc'
export const GA4_SWAP_SELF_LABOR_REWARD_TOOL_DOWNLOAD_EVENT_NAME =
  'swap_selfLaborRewardTool_download'

export const EnableAnalytics =
  WindowExist() &&
  !(
    window.location.origin.indexOf('staging.swap.work') >= 0 ||
    window.location.origin.indexOf('localhost') >= 0
  )

/**
 * 讀取 local storage 中的事件資料
 */
export const getGA4EventFromLocalStorage = () => {
  const localStorageEvent = localStorage.getItem(GA_LOCAL_STORAGE_EVENT_NAME)
  return localStorageEvent ? JSON.parse(localStorageEvent) : []
}

/**
 * 增加事件資料到 local storage 中
 */
export const addGA4EventToLocalStorage = (event: {
  name: string
  obj: { [key: string]: string | number | Function | undefined }
}) => {
  const localStorageEventArray = getGA4EventFromLocalStorage()
  localStorageEventArray.push(event)
  localStorage.setItem(
    GA_LOCAL_STORAGE_EVENT_NAME,
    JSON.stringify(localStorageEventArray)
  )
}

/**清空事件的 local storage */
export const clearGA4EventLocalStorage = () => {
  localStorage.setItem(GA_LOCAL_STORAGE_EVENT_NAME, '[]')
}

/**發送事件 */
export const handleSendGA4Event = (eventName: string, eventObj?: object) => {
  const debug_mode = !EnableAnalytics
  if (debug_mode) console.log('> GA4 in debug_mode')
  try {
    window.dataLayer = window.dataLayer || []

    function gtag(..._: any[]) {
      window.dataLayer.push(arguments)
    }

    gtag('js', new Date())

    //初始化，並加入 user_id。soho 端 id 優先
    const user_id =
      PathOr('', ['id'], ParseAccountToken()) ||
      PathOr('', ['id'], ParseAccountToken('biz'))

    if (user_id) {
      gtag('config', GA4_MEASUREMENT_ID, {
        user_id,
        ...(debug_mode ? { debug_mode: true } : {}),
      })
    } else {
      gtag('config', GA4_MEASUREMENT_ID, {
        ...(debug_mode ? { debug_mode: true } : {}),
      })
    }

    //發送事件
    gtag('event', eventName, {
      ...eventObj,
      send_to: GA4_MEASUREMENT_ID,
      ...(debug_mode ? { debug_mode: true } : {}),
    })
  } catch (err) {
    console.log(`GA4 Init error`, err)
  }
}
