import { makeStyles } from '@material-ui/core'
import { SWAPSpace, Button, Typography, TextField } from '@yosgo/swap-ui'
import { useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { FreshChatInit, FreshChatOpen } from './FreshChat'
import Fade from './Fade'

const useStyles = makeStyles(() => ({
  hide: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid #4862cc',
    boxSizing: 'border-box',
    borderRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    right: 15,
    bottom: 20,
    zIndex: 1298,
    cursor: 'pointer',
    animationName: '$leave',
    animationDuration: '500ms',
    transition: ' all 500ms ease-in-out',
    opacity: 0,
    transform: 'scale(0)',
    willChange: 'opacity, transform',
  },
  appear: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid #4862cc',
    boxSizing: 'border-box',
    borderRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    right: 15,
    bottom: 20,
    zIndex: 1298,
    cursor: 'pointer',
    animationName: '$appear',
    animationDuration: '500ms',
    transition: ' all 500ms ease-in-out',
    willChange: 'opacity, transform',
  },
  ctaHide: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid #4862cc',
    boxSizing: 'border-box',
    borderRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    right: 'calc(50vw - 160px)',
    bottom: 20,
    zIndex: 1298,
    cursor: 'pointer',
    opacity: 0,
    transform: 'scale(0)',
  },
  ctaAppear: {
    overflow: 'hidden',
    width: 300,
    minHeight: 180,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid rgba(0,0,0,0.15)',
    filter: 'drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.1))',
    boxSizing: 'border-box',
    borderRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    right: 'calc(50vw - 160px)',
    bottom: 20,
    zIndex: 1298,
    cursor: 'pointer',
  },
  '@keyframes appear': {
    from: {
      opacity: 0.4,
      transform: 'scale(0.2)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  '@keyframes leave': {
    from: {
      opacity: 1,
      transform: 'scale(1)',
    },
    to: {
      opacity: 0.4,
      transform: 'scale(0.2)',
    },
  },
}))

const FreshchatButton = () => {
  const [isNoFreshchatButton, setisNoFreshchatButton] = useState(false)
  const [isFirstOpen, setIsFirstOpen] = useState(false)
  const [isHide, setIsHide] = useState(false)
  const [showCTA, setShowCTA] = useState(false)
  const [email, setEmail] = useState('')
  const [emailMsg, setEmailMsg] = useState('')

  const handleCloseCta = () => {
    setShowCTA(false)
    window.localStorage.setItem('cta_window_status', 'close')
  }

  const handleShowCta = () => {
    const splitArray = window.location.pathname.split('/')
    // 在首頁相關頁面
    // 捲動到 50%
    // 瀏覽器本機儲存內沒有關閉的紀錄
    let isOnRelativePage = splitArray[1] === 'homeRelativePage'
    let scrollPercent =
      (document.documentElement.scrollTop /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight)) *
      100
    let cta_window_status = window?.localStorage?.getItem('cta_window_status')
    if (
      isOnRelativePage &&
      scrollPercent > 70 &&
      cta_window_status !== 'close'
    ) {
      setShowCTA(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleShowCta)
  }, [])

  useEffect(() => {
    FreshChatInit()
    // 判斷是否隱藏Freshchat button
    const splitArray = window.location.pathname.split('/')
    if (
      splitArray[1] === 'q' ||
      splitArray[1] === 'i' ||
      (window.innerWidth >= 768 && splitArray[1] === 'projects') ||
      splitArray.includes('launchpad') ||
      splitArray.includes('tools')
    ) {
      setisNoFreshchatButton(true)
    }
  }, [])

  useEffect(() => {
    let W: any = window
    if (W.fcWidget) {
      W.fcWidget.on('widget:closed', function (_resp: any) {
        setIsHide(false)
      })
    }
  }, [isFirstOpen])

  const classes = useStyles()
  return (
    !isNoFreshchatButton && (
      <>
        {showCTA ? (
          <div className={isHide ? classes.ctaHide : classes.ctaAppear}>
            <Fade seconds={1.8}>
              <div style={{ width: '100%', padding: '18px' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant="h5">SWAP 歡迎你</Typography>
                  <CloseIcon onClick={() => handleCloseCta()} />
                </div>
                <SWAPSpace size={12} />
                <Typography variant="body2">
                  <>
                    我們準備了
                    <span style={{ color: '#3f51b5', fontWeight: 600 }}>
                      自由工作者的財報模板
                    </span>
                    ，讓你輕鬆掌握自由ㄊ事業的財務狀況！
                  </>
                </Typography>
                <SWAPSpace />
                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'flex-start',
                  }}
                >
                  <>
                    <TextField
                      value={email}
                      onChange={(e) => {
                        setEmailMsg('')
                        setEmail(e.target.value)
                      }}
                      type="email"
                      placeholder="你的 Email"
                      height={42}
                      style={{ fontSize: '15px' }}
                      helperText={
                        emailMsg ? (
                          <Typography variant="small2" color="danger400">
                            {emailMsg}
                          </Typography>
                        ) : (
                          ''
                        )
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => {
                        if (email) {
                          handleCloseCta()
                        } else {
                          setEmailMsg('請輸入信箱')
                        }
                      }}
                    >
                      免費領取
                    </Button>
                  </>
                </div>
              </div>
            </Fade>
          </div>
        ) : null}
        <div
          className={isHide ? classes.hide : classes.appear}
          onClick={() => {
            setIsHide(true)
            FreshChatOpen()
            if (!isFirstOpen) {
              setIsFirstOpen(true)
            }
          }}
          id="newFreshChatButton"
        >
          <img src="https://swap-img.swap.work/media/FreshchatIcon.svg" />
        </div>
      </>
    )
  )
}

export default FreshchatButton
