/**
 * 如何優雅安全地在深層資料結構中取值
 * https://codertw.com/%E5%89%8D%E7%AB%AF%E9%96%8B%E7%99%BC/26341/
 * @param r
 * 若無法解析則使用指定的值
 * @param p
 * 解析的路徑
 * @param o
 * 被解析的物件
 */
export const PathOr = (r: any, p: any, o: any) => {
  const result = p.reduce((xs: any, x: any) => (xs && xs[x] ? xs[x] : null), o);
  return result ? result : r;
};

/**
 * 處理純文字
 * url 字串轉換為超連結元件
 * 信箱轉為超連結元件
 * 換行轉為斷行元件
 * https://ourcodeworld.com/articles/read/97/how-to-convert-url-websites-email-from-a-string-to-html-a-tags-with-javascript
 */
export const StringToHtml = (string: string) => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;
  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = string.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );
  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );
  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText.replace(/\n/g, "<br/>");
};

/**
 * 將數字轉化為金錢的格式
 */
export const LocalNumber = (number: number | string) => {
  return Number(number).toLocaleString();
};
