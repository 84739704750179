declare const API_URL: string

import axios from 'axios'
import { ReadAccountToken } from '../../HandleToken'
import { CheckAccountToken, CheckAPIURL } from '../Check'

/**
 * 對象：SOHO會員
 * 動作：建立贊助方案訂單
 */
interface CreateSponsorOrderFromSOHOInputData {
  notification_email: string
  phone: string
}

export const createSponsorOrderFromSOHO = async (
  inputData: CreateSponsorOrderFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation ($inputData: CreateSponsorOrderFromSOHOInputData){
            createSponsorOrderFromSOHO(inputData: $inputData) {  
              payload{
                virtual_bank_account
              }   
              message
              status
              errors
            }
          }`,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.createSponsorOrderFromSOHO
    const errors = res.data.data.createSponsorOrderFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of createSponsorOrderFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO會員
 * 動作：取得贊助方案訂單
 */
interface GetSponsorOrdersFromSOHOInputData {
  offset: number
  limit: number
}

export const getSponsorOrdersFromSOHO = async (
  inputData: GetSponsorOrdersFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          query ($offset: Int, $limit: Int){
            getSponsorOrdersFromSOHO(offset: $offset, limit: $limit) {  
              payload{
                id
                title
                is_pay
                pay_at
                amount
                invoice_info
                started_at
                end_at
                created_at
                updated_at
                virtual_bank_account
              }
              metadata {
                is_expired
                can_renew_sub
                non_expired_orders {
                  id
                  virtual_bank_account
                  title
                  started_at
                  end_at
                }
                pre_order {
                  id
                  virtual_bank_account
                  title
                  started_at
                  end_at
                }
              }   
              pagination{
                count
                limit
                offset
              }
              message
              status
              errors
            }
          }`,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getSponsorOrdersFromSOHO
    const errors = res.data.data.getSponsorOrdersFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of getSponsorOrdersFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO會員
 * 動作：刪除贊助方案訂單
 */
interface DeleteSponsorOrderFromSOHOInputData {
  id: number
}

export const deleteSponsorOrderFromSOHO = async (
  inputData: DeleteSponsorOrderFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation ($inputData: DeleteSponsorOrderFromSOHOInputData){
            deleteSponsorOrderFromSOHO(inputData: $inputData) {               
              message
              status
              errors
            }
          }`,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.deleteSponsorOrderFromSOHO
    const errors = res.data.data.deleteSponsorOrderFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of deleteSponsorOrderFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO會員
 * 動作：排除贊助方案 (到期後將身分轉為標準方案)
 */

export const updateSelfIdentityFromSOHO = async () => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation {
            updateSelfIdentityFromSOHO {               
              message
              status
              errors
            }
          }`,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateSelfIdentityFromSOHO
    const errors = res.data.data.updateSelfIdentityFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of updateSelfIdentityFromSOHO', err)
  }
  return result
}
