declare const API_URL: string
import { ReadAccountToken } from '../HandleToken'

export const CheckAPIURL = () => {
  if (!API_URL) throw 'API_URL 不存在'
}
export const CheckAccountToken = (type?: string) => {
  if (type === 'biz') {
    if (!ReadAccountToken('biz')) throw '請重新登錄'
  }
  if (type === 'member') {
    if (!ReadAccountToken('member')) throw '請重新登錄'
  }
  if (!type) {
    if (!ReadAccountToken()) throw '請重新登錄'
  }
}
