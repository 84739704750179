import { useMediaQuery } from '@material-ui/core'
import { Modal, SWAPSpace, Typography } from '@yosgo/swap-ui'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { OrderDataProps } from '.'
import { GetMember } from '../../../utils/AppRequest'
import {
  getSponsorOrdersFromSOHO,
  updateSelfIdentityFromSOHO,
} from '../../../utils/appRequest/price/plan'
import { ParseAccountToken } from '../../../utils/HandleToken'
import PathAndTitle from '../../../utils/PathAndTitle'

const ExpiredModal = () => {
  const [isOpenExpiredModal, setIsOpenExpiredModal] = useState(false)
  const [memberPlan, setMemberPlan] = useState('')
  const [orderData, setOrderData] = useState<OrderDataProps>({
    amount: 0,
    created_at: '',
    end_at: '',
    invoice_info: '',
    is_pay: false,
    pay_at: '',
    started_at: '',
    title: '',
    updated_at: '',
    virtual_bank_account: '',
    id: 0,
  })

  const handleGetMember = async () => {
    try {
      const res: any = await GetMember(`payload{
    member{
      identity
      subscribe_expired_at
      }
  }`)
      if (res && res.errors === null) {
        const data = res.payload.member
        setMemberPlan(data.identity)

        const token: any = ParseAccountToken()
        if (
          data.identity === 'subscribe' &&
          dayjs().isAfter(Number(data.subscribe_expired_at)) &&
          token.type === 'member'
        ) {
          setIsOpenExpiredModal(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetSponsorOrders = async () => {
    try {
      const res = await getSponsorOrdersFromSOHO({
        offset: 0,
        limit: 1,
      })
      if (res && res.errors === null) {
        setOrderData(res.payload[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateSelfIdentity = async (to?: 'plan' | 'price') => {
    try {
      const res = await updateSelfIdentityFromSOHO()
      if (res && res.errors === null) {
        if (to === 'plan') {
          window.location.href = PathAndTitle.member.plan.path
        } else if (to === 'price') {
          window.location.href = '/pricing'
        } else {
          setIsOpenExpiredModal(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetMember()
  }, [])

  useEffect(() => {
    if (memberPlan === 'subscribe') {
      handleGetSponsorOrders()
    }
  }, [memberPlan])

  const match768 = useMediaQuery('(min-width:768px)')
  return (
    <Modal
      mobile={!match768}
      fullWidth={!match768}
      open={isOpenExpiredModal}
      onClose={handleUpdateSelfIdentity}
      maxWidth={match768 ? 640 : undefined}
      title="方案已到期"
      primaryButton={{
        title: '繼續訂閱贊助方案',
        onClick: () => {
          handleUpdateSelfIdentity('plan')
        },
      }}
      secondaryButton={{
        title: '查看方案',
        onClick: () => {
          handleUpdateSelfIdentity('price')
        },
      }}
      children={
        <>
          <Typography variant="title">
            您訂閱的 {orderData?.title}已到期，是否繼續訂閱？
          </Typography>
          <SWAPSpace size={12} />
          <Typography variant="body2" color="black700">
            固定費率：1 %
          </Typography>
          <SWAPSpace size={4} />
          <Typography variant="body2" color="black700">
            訂購日：{dayjs(Number(orderData?.started_at)).format('YYYY-MM-DD')}
          </Typography>
          <SWAPSpace size={4} />
          <Typography variant="body2" color="black700">
            到期日：
            <b style={{ color: '#E5640C' }}>
              {dayjs(Number(orderData?.end_at)).format('YYYY-MM-DD')}
            </b>
          </Typography>
        </>
      }
    />
  )
}

export default ExpiredModal
