import {
  GetBusiness,
  GetMember,
  UpdateFCRestoreIdFromBiz,
  UpdateMemberFCRestoreId,
} from '../../utils/AppRequest'
import { PathOr } from '../../utils/FunctionsBox'
import { ParseAccountToken } from '../../utils/HandleToken'

interface FreshChatPropertiesProps {
  email?: string
  name?: string
  type?: 'member' | 'business'
}

/* 保存restore_id API */
/* SOHO || Biz */
const handleUpdateMemberFCRestoreId = async (
  restore_id: string,
  type: 'member' | 'business'
) => {
  const result: any =
    type === 'member'
      ? await UpdateMemberFCRestoreId({
          restore_id: restore_id,
        })
      : await UpdateFCRestoreIdFromBiz({
          restore_id: restore_id,
        })
  if (result) {
    if (result.errors === null) {
    } else {
      console.log(result.errors)
    }
  }
}

/* 取得用戶的資料*/
export function FreshChatGetUser() {
  let W: any = window
  return W.fcWidget.user.get()
}

/* 設定用戶的external_id */
export function FreshChatSetExternalId(externalId: string) {
  let W: any = window
  if (externalId) {
    return W.fcWidget.setExternalId(externalId)
  }
}

/* 開啟聊天室 */
export function FreshChatOpen() {
  FreshChatChangeCSSStyle({ zIndex: 1301, display: 'block' })
  let W: any = window
  W.fcWidget.open()
}

/* 設定用戶的資料 */
export function FreshChatSetProperties({
  email,
  type,
}: FreshChatPropertiesProps) {
  let W: any = window

  FreshChatGetUser().then(function (r: any) {
    if (r) {
      let d = r.data
      if (email || type) {
        if (d.email !== email || d.meta.type !== type) {
          W.fcWidget.user.setProperties({
            email: email,
            type: type,
          })
        }
        if (type) {
          ;(async () => {
            const result: any =
              type === 'member'
                ? await GetMember(
                    `
              payload{
                member{
                  verified_name
                }
              }
              `
                  )
                : await GetBusiness(
                    `
              payload{
                business{
                  verified_company_name
                }
              }
              `
                  )
            if (result) {
              if (result.errors === null) {
                const name =
                  type === 'member'
                    ? result.payload.member.verified_name
                    : result.payload.business.verified_company_name
                if (d.firstName !== name) {
                  W.fcWidget.user.setProperties({
                    firstName: name,
                  })
                }
              } else {
                console.log(result.errors)
              }
            }
          })()
        }
      }
    }
  })
}

/* 保存restore_id (使用情境：未登入時開啟對話) */
/* SOHO */
export async function FreshChatSaveRestoreId(externalId: string) {
  await FreshChatGetUser().then(
    async (res: any) => {
      if (res) {
        let restoreId = res.data.restoreId
        if (!restoreId) {
          if (externalId) {
            await FreshChatSetExternalId(externalId).then(async (r: any) => {
              await handleUpdateMemberFCRestoreId(r.data.restoreId, 'member')
            })
          }
        }
      }
    },
    (rej: any) => console.log(rej)
  )
}
/* Biz */
export async function FreshChatSaveRestoreIdFromBiz(externalId: string) {
  await FreshChatGetUser().then(
    async (res: any) => {
      if (res) {
        let restoreId = res.data.restoreId
        if (!restoreId) {
          if (externalId) {
            await FreshChatSetExternalId(externalId).then(async (r: any) => {
              await handleUpdateMemberFCRestoreId(r.data.restoreId, 'business')
            })
          }
        }
      }
    },
    (rej: any) => console.log(rej)
  )
}

/** Freshchat 初始化 */
export function FreshChatInit() {
  let W: any = window

  function initFreshChat() {
    let externalId, restoreId, email: any, type: any

    if (
      window.location.href.includes('/business' || '/biz') &&
      !window.location.href.includes('/partner-invite')
    ) {
      externalId = PathOr(undefined, ['id'], ParseAccountToken('biz'))
      restoreId = PathOr(undefined, ['fc_restore_id'], ParseAccountToken('biz'))
      email = PathOr(undefined, ['email'], ParseAccountToken('biz'))
      type = PathOr(undefined, ['type'], ParseAccountToken('biz'))
    } else {
      ;(externalId = PathOr(undefined, ['id'], ParseAccountToken())),
        (restoreId = PathOr(undefined, ['fc_restore_id'], ParseAccountToken())),
        (email = PathOr(undefined, ['email'], ParseAccountToken())),
        (type = PathOr(undefined, ['type'], ParseAccountToken()))
    }

    W.fcWidget.init({
      token: 'e7da6752-9d1f-4425-9d5c-59687eddcef3',
      host: 'https://wchat.freshchat.com',
      externalId: externalId ? externalId : null,
      restoreId: restoreId ? restoreId : null,
      locale: 'zh-HANT',
      config: {
        content: {
          headers: {
            chat: '聯繫線上客服',
            faq: '常見問與答',
          },
        },
        headerProperty: {
          hideChatButton: true,
        },
      },
    })

    FreshChatChangeCSSStyle({ zIndex: 1298 })
    W.fcWidget.on('widget:opened', function (_resp: any) {})
    W.fcWidget.on('widget:closed', function (_resp: any) {
      FreshChatChangeCSSStyle({ zIndex: 1298 })
    })

    W.fcWidget.user.get(function (resp: any) {
      let status = resp && resp.status
      if (status === 200) {
        FreshChatSetProperties({ email, type })
      }
      if (status !== 200) {
        /* 保存restore_id (使用情境：登入時開啟對話)  */
        W.fcWidget.on('user:created', function (resp: any) {
          let status = resp && resp.status,
            restoreId = resp && resp.data.restoreId
          if (status === 200) {
            if (restoreId) {
              FreshChatSetProperties({ email, type })
              handleUpdateMemberFCRestoreId(restoreId, type)
            } else {
              console.log('No RestoreId')
            }
          }
        })
      }
    })
  }

  function initialize(i: any, t: any) {
    var e
    i.getElementById(t)
      ? initFreshChat()
      : (((e = i.createElement('script')).id = t),
        (e.async = !0),
        (e.src = 'https://wchat.freshchat.com/js/widget.js'),
        (e.onload = initFreshChat),
        i.head.appendChild(e))
  }
  function initiateCall() {
    initialize(document, 'Freshchat-js-sdk')
  }
  initiateCall()
}

/** 控制Freshchat右下角的CSS  */
export function FreshChatChangeCSSStyle(style: Object) {
  const elem: any = document.getElementById('fc_frame')
  if (elem) {
    Object.keys(style).forEach((i, index) => {
      elem.style[i] = Object.values(style)[index]
    })
  }
}

export function changeNewFreshChatCssStyle(style: Object) {
  const elem: any = document.getElementById('newFreshChatButton')
  Object.keys(style).forEach((i, index) => {
    elem.style[i] = Object.values(style)[index]
  })
}

/** 設定頻道 */
export function FreshChatSetChannel(tag?: string) {
  let W: any = window
  if (tag) {
    W.fcWidget.setTags([tag])
    W.fcWidget.setFaqTags({ tags: [tag], filterType: 'category' })
  } else {
    W.fcWidget.setTags()
    W.fcWidget.setFaqTags()
  }
}
