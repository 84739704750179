/**
 * 對象: 內部人員
 * 操作: 透過網址上的 query string 決定目前的頁面型態，供內部溝通使用
 * 備註: 簡單區分為兩種版本，現行版本以及Beta 版本，beta 版本則在網址上加上 mode=beta 的 QS。透過是否為 beta 在佈局上進行變動。
 */
import QS from "./QS";
import { PathOr } from "./FunctionsBox";

const modeKey = "mode";
const betaKey = "beta";

export const IsBeta = () => {
  if (typeof window !== "undefined") {
    return QS(modeKey, PathOr("", ["location", "href"], window)) === betaKey;
  } else {
    return false;
  }
};

/**
 * 對象: 需要用到 window 的頁面或元件
 * 操作: 當 window 存在時才渲染整個頁面或是元件
 */
export const WindowExist = () => {
  if (typeof window !== "undefined") {
    return true;
  } else {
    return false;
  }
};
