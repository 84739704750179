import jwt from "jsonwebtoken";

import PathAndTitle from "./PathAndTitle";
/* 一般SOHO用戶帳號token key */
export const AccountTokenKey = "account";
/* Biz用戶帳號token key */
export const BusinessAccountTokenKey = "businessAccount";

/**寫入 Account token */
export const WriteAccountToken = (token: string) => {
  const decoded = jwt.decode(token);
  if (Object(decoded).type === "business") {
    window.localStorage.setItem(BusinessAccountTokenKey, token);
  }
  if (Object(decoded).type === "member") {
    window.localStorage.setItem(AccountTokenKey, token);
  }
};

/* 
讀取 Account token 
參數 type 判斷 biz or member
無參數則預設 member 
*/
export const ReadAccountToken = (type?: string) => {
  let token;
  if (type === "biz") {
    token = window.localStorage.getItem(BusinessAccountTokenKey);
  }
  if (type === "member") {
    token = window.localStorage.getItem(AccountTokenKey);
  }
  if (!type) {
    token = window.localStorage.getItem(AccountTokenKey);
  }
  return token;
};

/* 
解析 Account token
參數 type 判斷 biz or member
無參數則預設 member 
*/
export const ParseAccountToken = (type?: string) => {
  if (type === "biz") {
    const token = window.localStorage.getItem(BusinessAccountTokenKey);
    if (token) {
      {
        /**
         * {id: "o93_GHg9", email: "kcin1993@gmail.com", type: "business", created_at: "2020-01-21T04:14:54.303Z", iat: 1579583439}
         */
      }
      return jwt.decode(token);
    } else {
      console.log("Account token is missing. Please login");
    }
  }
  if (type === "member") {
    const token = window.localStorage.getItem(AccountTokenKey);
    if (token) {
      {
        /**
         * {id: "o93_GHg9", email: "kcin1993@gmail.com", type: "business", created_at: "2020-01-21T04:14:54.303Z", iat: 1579583439}
         */
      }
      return jwt.decode(token);
    } else {
      console.log("Account token is missing. Please login");
    }
  }
  if (!type) {
    const token = window.localStorage.getItem(AccountTokenKey);
    if (token) {
      {
        /**
         * {id: "o93_GHg9", email: "kcin1993@gmail.com", type: "business", created_at: "2020-01-21T04:14:54.303Z", iat: 1579583439}
         */
      }
      return jwt.decode(token);
    } else {
      console.log("Account token is missing. Please login");
    }
  }
};

/*
移除 Account token
參數 type 判斷 biz or member
無參數則預設 member 
*/
export const RemoveAccountToken = (type?: string) => {
  if (type === "biz") {
    window.localStorage.removeItem(BusinessAccountTokenKey);
  }
  if (type === "member") {
    window.localStorage.removeItem(AccountTokenKey);
  }
  if (!type) {
    window.localStorage.removeItem(AccountTokenKey);
  }
};

/**
 * 判斷瀏覽器內是否有 Token
 * @forceRedirect
 * 若沒有 token 是否重新導向到登入頁面
 * @validCallback
 * 若有 token 觸發的 callback
 * @unvalidCallback
 * 若沒有 token 觸發的 callback
 * @type
 * 帳號類型member or biz
 **/
export const ValidAccountToken = (
  forceRedirect: boolean,
  validCallback?: Function,
  unvalidCallback?: Function,
  type?: string
) => {
  if (type === "biz") {
    if (ReadAccountToken("biz")) {
      /**有 Token */
      console.log("Account", ParseAccountToken("biz"));
      if (validCallback) validCallback();
    } else {
      /**無 Token */
      if (forceRedirect)
        window.location.href = `${PathAndTitle.common.auth.path}`;
      if (unvalidCallback) unvalidCallback();
    }
  }
  if (type === "member") {
    if (ReadAccountToken()) {
      /**有 Token */
      console.log("Account", ParseAccountToken("member"));
      if (validCallback) validCallback();
    } else {
      /**無 Token */
      if (forceRedirect)
        window.location.href = `${PathAndTitle.common.auth.path}`;
      if (unvalidCallback) unvalidCallback();
    }
  }
  if (!type) {
    if (ReadAccountToken()) {
      /**有 Token */
      console.log("Account", ParseAccountToken());
      if (validCallback) validCallback();
    } else {
      /**無 Token */
      if (forceRedirect)
        window.location.href = `${PathAndTitle.common.auth.path}`;
      if (unvalidCallback) unvalidCallback();
    }
  }
};
