declare const heap: any

const HeapUserIdentify = (userId: string) => {
  try {
    const _window: any = window
    if (userId && _window && _window.heap && _window.heap.identify) {
      _window.heap.identify(userId)
    }
  } catch (err) {
    console.log('> HeapUserIdentify error', err)
  }
}

export { HeapUserIdentify }
