const PathAndTitle = {
  common: {
    auth: {
      slug: 'auth',
      path: '/auth',
      title: '註冊與登入',
    },
    privacy: {
      slug: 'privacy',
      path: '/privacy-policy',
      title: '隱私權政策',
    },
  },
  admin: {
    home: {
      slug: 'home',
      path: '/admin',
      title: '管理後台',
    },
  },
  business: {
    home: {
      slug: 'home',
      path: '/business',
      title: '帳戶首頁',
    },
    init: {
      slug: 'init',
      path: '/business',
      title: '登入引導',
    },
    profile: {
      slug: 'profile',
      path: '/business/profile',
      title: '我的頁面',
    },
    message: {
      slug: 'message',
      path: '/business/message',
      title: '合作訊息',
    },
    settings: {
      slug: 'settings',
      path: '/business/settings',
      title: '帳戶設定',
    },
    overview: {
      slug: 'overview',
      path: '/business/overview',
      title: '帳務總覽',
    },
    partners: {
      slug: 'partners',
      path: '/business/partners',
      title: '合作夥伴',
    },
    projects: {
      slug: 'projects',
      path: '/business/projects',
      title: '匯款群組',
    },
    token: {
      slug: 'tokens',
      path: '/business/tokens',
      title: '企業額度',
      list: {
        slug: 'tokenList',
        path: '/business/settings?tab=records',
        title: '訂單紀錄',
      },
      buy: {
        slug: 'tokenBuy',
        path: '/business/tokens/buy',
        title: '企業加購',
      }
    },
  },
  member: {
    home: {
      slug: 'home',
      path: '/member',
      title: '帳戶總覽',
    },
    init: {
      slug: 'init',
      path: '/member?status=begin',
      title: '登入引導',
    },
    onboarding: {
      slug: 'onboarding',
      path: '/member/onboarding',
      title: '功能導覽',
    },
    group: {
      slug: 'group',
      path: '/member/group',
      title: '接案社團',
      news: {
        slug: 'news',
        path: '/member/group/news',
        title: '社團動態',
      },
      list: {
        slug: 'list',
        path: '/member/group/list',
        title: '社團列表',
      },
      detail:{
        slug: 'detail',
        path: '/member/group/detail/[id]',
        title: '社團詳情',
      }
    },
    profile: {
      slug: 'profile',
      path: '/member/profile',
      title: '接案履歷',
      create: {
        slug: 'create',
        path: '/member/profile/create',
        title: '創建接案履歷',
      },
      createWelcome: {
        slug: 'createWelcom',
        path: '/member/profile/create?page=welcome',
        title: '註冊歡迎',
      },
    },
    message: {
      slug: 'message',
      path: '/member/message',
      title: '合作訊息',
    },
    settings: {
      slug: 'settings',
      path: '/member/settings',
      title: '帳戶設定',
    },
    bills: {
      slug: 'bills',
      path: '/member/bills',
      title: '請款手續費紀錄',
    },
    achievements : {
      slug: 'achievements',
      path: '/member/achievements',
      title: '我的成就',
    },
    plan: {
      slug: 'plan',
      path: '/member/plan',
      title: '我的方案',
      sponsor: {
        slug: 'sponsor',
        path: '/member/plan/sponsor',
        title: '贊助方案訂單',
      },
    },
    
    withholdingStatement: {
      slug: 'withholdingStatement',
      path: '/member/withholdingstatement',
      title: '財力證明',
    },
    discount: {
      slug: 'discount',
      path: '/member/discount',
      title: '優惠券',
    },
    notify: {
      slug: 'notify',
      path: '/member/notify',
      title: '消息通知',
    },
    bizpartner: {
      slug: 'bizpartner',
      path: '/member/bizpartner',
      title: '合作企業',
    },
    projects: {
      slug: 'projects',
      path: '/member/projects',
      title: '我的專案',
      qts: {
        slug: 'qts',
        path: '/member/projects/qts',
        title: '報價單',
      },
      i: {
        slug: 'i',
        path: '/member/projects/i',
        title: '請款單',
      },
    },
    studio: {
      slug: 'studio',
      path: '/member/studio',
      title: '我的工作室',
    },
    files: {
      slug: 'files',
      path: '/member/files',
      title: '我的文件',
      qts: {
        slug: 'qts',
        path: '/member/files/qts',
        title: '報價單',
      },
      i: {
        slug: 'i',
        path: '/member/files/i',
        title: '請款單',
      },
    },
    pr: {
      slug: 'pr',
      path: '/member/pr',
      title: '我的請款單',
    },
  },
  terms: {
    member: {
      slug: 'memberTerm',
      path: '/terms',
      title: '會員使用協議',
    },
    business: {
      slug: 'businessTerm',
      path: '/terms',
      title: '商家合作協議',
    },
  },
}

export default PathAndTitle
