import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Chip, Modal, SWAPSpace, Typography, Skeleton } from "@yosgo/swap-ui";
import Slider from "react-slick";
import { useRouter } from 'next/router';

const useStyles = makeStyles(() => ({
  iconButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    border: "1px solid #cccccc",
    borderRadius: 5,
    width: 24,
    height: 24,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
  iconButtonDisabled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    border: "1px solid #cccccc",
    borderRadius: 5,
    width: 24,
    height: 24,
    opacity: 0.4,
    pointerEvents: "none",
  },
  closeButton: {
    position: "absolute",
    top: 16,
    right: 16,
    cursor: "pointer",
    zIndex: 1000,
  },
  skeleton: {
    width: "100%",
    height: 0,
    paddingBottom: "27%", // 16:9 aspect ratio
    marginBottom: 30
  },
}));

interface OnBoardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSessionClose: () => void;
}

const onBoardingModal:React.FC<OnBoardingModalProps> = ({ isOpen, onClose, onSessionClose }) => {
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();

  const classes = useStyles();
  const match768 = useMediaQuery("(min-width:768px)");
  const sliderRef = useRef<Slider>(null);

  const logoSettings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    autoplaySpeed: 4000,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current : number) => setStep((current % 3) + 1),
  };

  const handleNext = () => {
    setStep((prevStep) => (prevStep % 3) + 1);
    sliderRef.current?.slickNext();
  };

  const handlePrev = () => {
    setStep((prevStep) => (prevStep - 2 + 3) % 3 + 1);
    sliderRef.current?.slickPrev();
  };

  interface ContentProps {
    step: number;
    isMobile?: boolean;
  }

  const Content : React.FC<ContentProps> = ({ step, isMobile = false })  => {
    const classes = useStyles();
    const [imagesLoaded, setImagesLoaded] = useState<{ [key: number]: boolean }>({1: false, 2: false, 3: false});

    const mediaPath = "https://swap-img.swap.work/achievement";
    const content = [
      {
        icon: Icon_check(isMobile),
        text: "完成 SWAP 發佈的官方任務，獲得\n精美獨特的成就徽章！",
        imageSrc: `${mediaPath}/Banner+-+Official+Mission.png`,
        alt: "official_task"
      },
      {
        icon: Icon_achievement(isMobile),
        text: "蒐集所有精美獨特的成就徽章！",
        imageSrc: `${mediaPath}/Onboarding+Img+-+2@2x.png`,
        alt: "badge_view"
      },
      {
        icon: Icon_share(isMobile),
        text: "分享成就徽章，展示你完成的豐功\n偉業給親朋好友知道吧！",
        imageSrc: `${mediaPath}/Onboarding+Img+-+3@2x.png`,
        alt: "share_badge"
      }
    ];

    const currentContent = content[step - 1];
    const iconSize = isMobile ? 60 : 32;
    const borderRadius = isMobile ? 15 : 10;

    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setImagesLoaded(prev => ({ ...prev, [step]: true }));
      };
      img.src = currentContent.imageSrc;
    }, [step, currentContent.imageSrc]);


    return (
      <div style={{ width: match768 ? 592 : "100%" }}>
        <div style={{ 
            display: "flex", 
            alignItems: "center",
          }}>
          <div style={{ 
                width: iconSize, 
                height : iconSize, 
                flexShrink: 0,
                background:
                step === 1 ? "#E6E9F8" :
                (step === 2 ? "#FEF8E3"  :
                (step === 3 ? "#E6F6E8" : "#FEF8E3" )),
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                borderRadius: borderRadius
           }}>
            <div style={{
              marginTop: step === 2 ? 5 : 0
            }}>
              {currentContent.icon}
            </div>
          </div>
          <div style={{ }}>
            <Typography variant="body2_loose" color="black800" 
            style={{ marginLeft: 16, 
              whiteSpace: isMobile ? 'pre-wrap' : 'nowrap',
            }}>
              {currentContent.text}
            </Typography>
          </div>
        </div>
        {!isMobile && (
        <>
          <SWAPSpace />
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {!imagesLoaded[step] ? (
              <Skeleton 
                animation="wave"
                variant="rect"
                className={classes.skeleton}
              />
            ) : (
              <img 
                src={currentContent.imageSrc} 
                alt={currentContent.alt} 
                style={{ width: "100%", height: "auto" }} 
              />
            )}
          </div>
        </>
        )}
      </div>
    );
  };

  // const CloseButton = () => (
  //   <div className={classes.closeButton} onClick={onClose}>
  //     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#000000"/>
  //     </svg>
  //   </div>
  // );
  

  return (
    <Modal
      size="medium"
      mobile={!match768}
      fullWidth={!match768}
      open={isOpen}
      onClose={onSessionClose}
      maxWidth={match768 ? 640 : "100vw"}
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          新功能上線 - <span style={{ color: "#4862CC" }}>成就任務</span>
          <Chip label="NEW" style={{ marginLeft: 6 }} />
        </div>
      }
      primaryButton={{
        title: "查看任務",
        onClick: () => {
          onClose();
          setLoading(true);
          router.push('/member/achievements').then(() => {
            setLoading(false);
          }).catch((error) => {
            console.error('Navigation failed:', error);
            setLoading(false);
          });
          // Handle navigation or action here
        },
        loading: loading,
        disabled: loading,
        style: { minWidth: 90 },
      }}
      secondaryButton={{
        title: "稍後再說",
        disabled: false,
        onClick: onSessionClose
        // onClick: function noRefCheck(){},
      }}
      bodyStyle={{ overflow: "hidden" }}
      children={
        <>
          {/* <CloseButton /> */}
            {match768 ? (
                <>
                <Slider ref={sliderRef} {...logoSettings}>
                    <Content step={1} />
                    <Content step={2} />
                    <Content step={3} />
                </Slider>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: 148, height: 24 }}>
                    <div className={classes.iconButton} onClick={handlePrev}>
                        {icon_arrowLeft}
                    </div>
                    {icon_threeDots(step)}
                    <div className={classes.iconButton} onClick={handleNext}>
                        {icon_arrowRight}
                    </div>
                    </div>
                </div>
                </>
            ) : (
              <>
                <Content step={1} isMobile={true} />
                <SWAPSpace size="m" />
                <Content step={2} isMobile={true} />
                <SWAPSpace size="m" />
                <Content step={3} isMobile={true} />
              </>
            )}
        </>
      }
    />
  );
};


const icon_arrowLeft = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.273 11.0533L7.21967 8L10.273 4.94L9.33301 4L5.33301 8L9.33301 12L10.273 11.0533Z"
        fill="#000000"
      />
    </svg>
  )
  
  const icon_arrowRight = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72656 11.0533L8.7799 8L5.72656 4.94L6.66656 4L10.6666 8L6.66656 12L5.72656 11.0533Z"
        fill="black"
      />
    </svg>
  )
  
  const icon_threeDots = (step: number) => {
    return (
      <svg
        width="20"
        height="4"
        viewBox="0 0 20 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="2" cy="2" r="2" fill={step == 1 ? "#909090" : "#ECECEC"} />
        <circle cx="10" cy="2" r="2" fill={step == 2 ? "#909090" : "#ECECEC"} />
        <circle cx="18" cy="2" r="2" fill={step == 3 ? "#909090" : "#ECECEC"} />
      </svg>
    )
  }

  const Icon_share = (isMobile : boolean ) => { 

    return isMobile ? (
<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <path d="M25.2502 8.06256L23.0315 10.2813L20.5471 7.79694V25.2501H17.4534V7.79694L14.969 10.2813L12.7502 8.06256L19.0002 1.81256L25.2502 8.06256ZM31.5002 15.8751V33.0626C31.5002 34.7813 30.094 36.1876 28.3752 36.1876H9.62524C7.89087 36.1876 6.50024 34.7813 6.50024 33.0626V15.8751C6.50024 14.1407 7.89087 12.7501 9.62524 12.7501H14.3127V15.8751H9.62524V33.0626H28.3752V15.8751H23.6877V12.7501H28.3752C30.094 12.7501 31.5002 14.1407 31.5002 15.8751Z" fill="#10A535"/>
</svg>
    ):(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M16.0001 5L14.5801 6.42L12.9901 4.83V16H11.0101V4.83L9.42012 6.42L8.00012 5L12.0001 1L16.0001 5ZM20.0001 10V21C20.0001 22.1 19.1001 23 18.0001 23H6.00012C4.89012 23 4.00012 22.1 4.00012 21V10C4.00012 8.89 4.89012 8 6.00012 8H9.00012V10H6.00012V21H18.0001V10H15.0001V8H18.0001C19.1001 8 20.0001 8.89 20.0001 10Z" fill="#10A535"/>
    </svg>
    )
  }

  const Icon_achievement  = (isMobile : boolean ) => { 
    return isMobile ? (
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <path d="M33.0625 17.4375C33.0625 26.1094 27.0625 34.2188 19 36.1875C10.9375 34.2188 4.9375 26.1094 4.9375 17.4375V8.0625L19 1.8125L33.0625 8.0625V17.4375ZM19 33.0625C24.8594 31.5 29.9375 24.5312 29.9375 17.7812V10.0938L19 5.21875L8.0625 10.0938V17.7812C8.0625 24.5312 13.1406 31.5 19 33.0625ZM23.7656 25.25L18.9531 22.3594L14.1562 25.25L15.4219 19.7812L11.1875 16.125L16.7812 15.6406L18.9531 10.4844L21.1406 15.625L26.7344 16.1094L22.4844 19.7812L23.7656 25.25Z" fill="#F7B52C"/>
</svg>
    ):(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M21 11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1L21 5V11ZM12 21C15.75 20 19 15.54 19 11.22V6.3L12 3.18L5 6.3V11.22C5 15.54 8.25 20 12 21ZM15.05 16L11.97 14.15L8.9 16L9.71 12.5L7 10.16L10.58 9.85L11.97 6.55L13.37 9.84L16.95 10.15L14.23 12.5L15.05 16Z" fill="#F7B52C"/>
    </svg>
  )
}

  const Icon_check  = (isMobile : boolean ) => { 
    return isMobile ? (
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <path d="M29.9375 4.9375H8.0625C7.2337 4.9375 6.43884 5.26674 5.85279 5.85279C5.26674 6.43884 4.9375 7.2337 4.9375 8.0625V29.9375C4.9375 30.7663 5.26674 31.5612 5.85279 32.1472C6.43884 32.7333 7.2337 33.0625 8.0625 33.0625H29.9375C30.7663 33.0625 31.5612 32.7333 32.1472 32.1472C32.7333 31.5612 33.0625 30.7663 33.0625 29.9375V8.0625C33.0625 7.2337 32.7333 6.43884 32.1472 5.85279C31.5612 5.26674 30.7663 4.9375 29.9375 4.9375ZM29.9375 8.0625V29.9375H8.0625V8.0625H29.9375ZM15.875 26.8125L9.625 20.5625L11.8281 18.3438L15.875 22.3906L26.1719 12.0938L28.375 14.3125" fill="#4862CC"/>
</svg>
    ):(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M19 3.5H5C4.46957 3.5 3.96086 3.71071 3.58579 4.08579C3.21071 4.46086 3 4.96957 3 5.5V19.5C3 20.0304 3.21071 20.5391 3.58579 20.9142C3.96086 21.2893 4.46957 21.5 5 21.5H19C19.5304 21.5 20.0391 21.2893 20.4142 20.9142C20.7893 20.5391 21 20.0304 21 19.5V5.5C21 4.96957 20.7893 4.46086 20.4142 4.08579C20.0391 3.71071 19.5304 3.5 19 3.5ZM19 5.5V19.5H5V5.5H19ZM10 17.5L6 13.5L7.41 12.08L10 14.67L16.59 8.08L18 9.5" fill="#4862CC"/>
    </svg>
  )
}
// Icon components (icon_link, icon_verify, icon_google, icon_arrowLeft, icon_arrowRight, icon_threeDots) 
// should be defined here as in your original code

export default onBoardingModal;

