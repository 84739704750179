import React, { useMemo } from 'react'

type Props = {
  seconds?: number
  children: React.ReactNode
}

const Fade = (props: Props) => {
  const animationDuration = useMemo(() => {
    return props.seconds ? `${props.seconds}s` : '0.5s'
  }, [props.seconds])

  return (
    <>
      <style>{`
        .swap-fade-wrap {
          will-change: opacity;
          animation-name: fade;
          animation-duration: ${animationDuration};
        }
        @keyframes fade {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
      <div className="swap-fade-wrap">{props.children}</div>
    </>
  )
}

export default Fade
